<template>
  <li class="nav-item">
    <div class="header-search">
      <i class="zmdi zmdi-search" id="omnibar-search-icon"></i>
      <input class="omnibar-search-input form-control-outline" v-model="tempValue" ref="user_search" placeholder="Search all jobs, eg: Ruby on Rails Jobs" id="omnibar-search-field" v-on:keyup.enter="sendSearchValueEnter" v-debounce:300ms="sendSearchValueTime"/>
    </div>
  </li>
</template>

<script>
  import Vue from "vue";

  // debounce the entry so we can tell if someone has stopped typing
  import vueDebounce from 'vue-debounce'
  Vue.use(vueDebounce)

  export default {
    data () {
      return {
        tempValue: null
      }
    },
    name: "SearchBar",
    methods: {
      sendSearchValueTime () {
        this.sendSearchValue('time')
      },
      sendSearchValueEnter() {
        this.sendSearchValue('enter')
      },
      sendSearchValue (mode) {
        if (window.location.pathname === "/" && mode == 'time') {
          return
        }

        console.log("sendSearchValue")
        this.$emit('input', this.tempValue)
        this.$emit('enter')
      },
      focusSearchInpht() {
        this.$nextTick( () => {
          this.$refs.user_search.$el.focus()
        });
      }
    },
    watch: {
      search_text (newVal) {

        if (newVal.length == 0) {
          this.$emit('input', '')
        }

      },
      value (newVal, oldVal) {
        if (newVal != oldVal) {
          this.tempValue = newVal
        }
      }
    },
    props: {
      value: {
        type: String
      }
    }
  }
</script>

<style lang="scss">

</style>

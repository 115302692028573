<template>
  <div class="new-job-component">

    <b-form-group
      id="fieldset-1"
      label="Enter your name"
      label-for="title"
      :invalid-feedback="invalidFeedback"
      :valid-feedback="validFeedback"
      :state="state">
      <b-form-input id="title" v-model="title" class="form-control-outline" placeholder="Job Title" autofocus trim/>
    </b-form-group>

    <skills-selection v-model="selected_skill_ids"/>

    <b-form-input v-model="unlisted_skills" class="form-control-xl form-control-outline" placeholder="Skills we are missing?" v-if="false == true"/>

    <h4>Category Type</h4>

    <div>
      <b-form-group label="Individual radios">
        <b-form-radio v-model="category_id" class="category_radio_wrapper" name="some-radios" :value="category.id" v-for="category in categories">{{category.name}}<br/></b-form-radio>
      </b-form-group>
    </div>

    <b-form-textarea
      id="description"
      v-model="description"
      rows="25">
    </b-form-textarea>

    <br/>
    <h4>Company Information</h4>

    <b-form-file
      v-model="company.logo"
      :state="Boolean(company.logo)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>

    <b-form-input v-model="company.name" class="form-control-xl" placeholder="eg: ACME inc."/>
    <b-form-input v-model="company.url" class="form-control-xl" placeholder="eg: example.com"/>
    <b-form-input v-model="company.email" class="form-control-xl" placeholder="eg: name@example.com"/>

    <b-form-input v-model="location" class="form-control-xl" placeholder="Location"/>
    <h4>Is working remotely allowed?</h4>

    <b-form-group label="Individual radios">
      <b-form-radio v-model="remote_type" name="some-radios" value="none">None</b-form-radio>
      <br/>
      <b-form-radio v-model="remote_type" name="some-radios" value="allowed">Allowed</b-form-radio>
      <br/>
      <b-form-radio v-model="remote_type" name="some-radios" value="remote_only">Remote Only</b-form-radio>
    </b-form-group>

    <br/>
    <br/>
    <h4>Position Type</h4>

    <b-form-group label="Individual radios">
      <b-form-radio v-model="position_type" name="some-radios" value="full_time">Full time</b-form-radio>
      <br/>
      <b-form-radio v-model="position_type" name="some-radios" value="part_time">Part time</b-form-radio>
      <br/>
      <b-form-radio v-model="position_type" name="some-radios" value="contract">Contract</b-form-radio>
    </b-form-group>

    <br/>
    <br/>
    <h4>Application Instructions (Choose 1)</h4>

    <div class="row">
      <div class="col-md-6">
        <b-form-input v-model="apply_url" class="form-control-xl" placeholder="eg: example.com/apply"/>
      </div>
      <div class="col-md-6">
        <b-form-input v-model="apply_email" class="form-control-xl" placeholder="eg: apply@example.com"/>
      </div>
    </div>

    <br/>

  </div>


</template>

<script>

  import Vue from 'vue';
  import Routes from 'routes';

  import { Requests } from 'Packs/requests';

  import SkillSelection from 'Components/SkillSelection.vue'

  import VueTimeago from 'vue-timeago'
  Vue.use(VueTimeago, {
    name: "timeago",
    locale: "en"
  })

  export default Vue.extend({
    name: "job-form",
    components: {
      'skills-selection': SkillSelection
    },
    methods: {
      getSkills() {
        Requests.get(Routes.api_skills_path()).then( (response) => {
          this.categories = response.data.job_categories
        })
      },
    },
    mounted() {
    },
    created() {
      this.getSkills()
    },
    data() {
      return {
        title: null,
        unlisted_skills: null,
        skills: [],

        category_id: null,

        description: null,
        remote_type: "none",
        company: {
          name: null,
          logo: null,
          url: null,
          email: null
        },
        location: "none",
        position_type: "full_time",
        apply_url: null,
        apply_email: null,
        categories: [],

        selected_skill_ids: []
      }
    },
  });


</script>

<style lang="scss">
.category_radio_wrapper {
  display: block;
  margin-bottom: 10px;
  font-size: 1.5rem;
  line-height: 1.5rem;

  .custom-control-label {
    display: block;
  }
}
</style>
<template>
  <div class="job-card">
    <a v-bind:href="job.path">
      <div class="top clearfix">
        <div class="left float-left">
          <img class="company-avatar float-left" v-bind:alt="job.company.name" v-bind:src="job.company.logo_url">
            <div class="titles float-left">
              <h3 class="job-title">
                {{job.title}}
              </h3>
              <h3 v-show="false == true">
                <span class="global-skill badge">
                  {{job.category.name}}
                </span>
              </h3>
              <h5>
                {{job.company.name}}
                <span> • {{job.published_at.iso8601 | moment("MMMM Do")}}</span>
              </h5>
            </div>
        </div>
        <div class="right float-left clearfix">
          <p class="position-type">{{job.position_type.name}}</p>
          <p class="location">
            <template v-if="job.remote_only">
              <span style="margin-left:5px; padding: 2px 4px; border-radius: 4px;">
                <span class="zmdi zmdi-wifi-alt" style="margin-right: 5px; color: #00BCD4"></span>
                REMOTE ONLY
              </span>

            </template>
            <template v-else>
              {{job.location.city_with_state}}
              <span class="zmdi zmdi-wifi-alt" style="margin-left:5px; color: #00BCD4" v-if="job.remote.allowed"></span>
            </template>
          </p>
        </div>
      </div>
      <div class="bottom clearfix">
        <div class="left float-left">
          <p class="description">{{job.description}}</p>
        </div>
        <div class="right float-left">
          <span v-for="skill in job.skills" class="float-right skill" v-bind:class="'skill-id-'+skill"></span>
        </div>
      </div>
    </a>
  </div>

</template>

<script>
import Vue from 'vue';

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

export default Vue.extend({
  name: "job-row",
  computed: {
  },
  props: {
    job: {
      remote_only: {
        type: Boolean,
        default: true
      },
      remote_allowed: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: "FOOBAR"
      },
      category_name: {
        type: String,
        default: "FOOBAZ"
      },
      position_type: {
        type: String,
        default: "Full-Time"
      },
      location: {
        type: String,
        default: ""
      },
      description: {
        type: String,
        default: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus. Vestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultri..."
      },
      company: {
        type: Object,
        default: function() {
          return { name: "MyCompany", logo_url: "http://lvh.me:3000/uploads/job_company_logos/d6670e4d/thumb_2x_adobe_logo.png" }
        }
      },
      published_at: {
        type: String,
        default: "2018-10-03T02:58:58Z"
      },
      skills: {
        type: Array,
        default: function() {
          return ["css", "go", "ruby", "spring", "ruby", "php", "wordpress", "linux", "docker"]
        }
      },
      job_link: String,
      id: String,
      path: String

    },
  }
});
</script>

<template>
  <b-button :style="{'background-color': color.background, 'color': color.text, 'margin': '2px', 'font-weight': '700'}" v-on:click="selected">
    <span class="zmdi zmdi-close-circle" style="margin-right: 5px;" v-if="actionType == 'X'"></span>
    <span class="zmdi zmdi-plus" style="margin-right: 5px;" v-if="actionType == '+'"></span>
    {{name}} ({{count}})</b-button>
</template>

<script type="text/javascript">

  import Vue from "vue";
  import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)

  export default Vue.extend({
    data: function(){
      return { }
    },
    components: {

    },
    methods: {
      selected() {
        this.$emit("selected", this.$props)
      }
    },
    created() {
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    props: {
      id: {
        type: Number
      },
      name: {
        type: String
      },
      pretty_param: {
        type: String
      },
      color: {
        background: String,
        text: String
      },
      count: {
        type: Number
      },
      actionType: {
        type: String
      }

    },
  })

</script>


<style lang="scss">
</style>

import { jqlite } from 'Packs/jqlite'

export module ProgressBar {

  function progressBarElement() {
    return <HTMLElement>document.querySelector(".progress-bar");
  }

  function updateWidth(newWidth) {
    const progressBar = progressBarElement();

    if (progressBar !== null) {
      progressBar.style.width = `${newWidth}%`;
    }
  }

  export function start() {
    jqlite.removeClass(progressBarElement(), 'hidden')

    updateWidth(10)
  }

  export function update(progress) {
    jqlite.removeClass(progressBarElement(), 'hidden')

    updateWidth(progress)
  }

  export function finish() {
    const progressBar = progressBarElement();

    if (progressBar !== null) {
      updateWidth(100)

      setTimeout(() => {
        jqlite.toggleClass(progressBar,'hidden')
      }, 400);

      setTimeout(() => {
        updateWidth(0)
      }, 800);
    }
  }
}

window['pStart']  = ProgressBar.start
window['pUpdate'] = ProgressBar.update
window['pFinish'] = ProgressBar.finish

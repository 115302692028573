// http://youmightnotneedjquery.com/
// https://github.blog/2018-09-06-removing-jquery-from-github-frontend/

// https://www.npmjs.com/package/cash-dom

export namespace jqlite {

  export function query(selector: string) {
    return document.getElementById(selector);
  }

  export function attr(el: HTMLElement, name: string, val?: any):  (string | undefined) {
    if (val !== undefined) {
      el.setAttribute(name, val);
    } else {
      return el.getAttribute(name);
    }
  }

  export function removeAttr(el: HTMLElement, name: string) {
    el.removeAttribute(name)
  }

  // hides an HTML element, upon hide the display state is stores
  export function hide(el: HTMLElement) {
    if (el && el.style.display === 'none') {
      return
    }

    if (el) {
      // store the style before modifying if not equal to ''
      let displayBefore = el.style.display;

      if (displayBefore !== '') {
        attr(el, 'jqlite-hide-display-before', displayBefore)
      }

      el.style.display = 'none';
    } else {
      console.warn("Element",el,"is not defined")
    }
  }

  // shows an HTML element, if hide() is used, the display style will be stored and restored upon show()
  export function show(el: HTMLElement) {
    if (el && el.style.display !== 'none') {
      return
    }

    if (el) {
      let displayBefore = attr(el, 'jqlite-hide-display-before')

      if (displayBefore !== null) {
        // restore the style
        el.style.display = displayBefore
        removeAttr(el, 'jqlite-hide-display-before')
      } else {
        el.style.display = ''
        // if there are no other style tags, then remove the attr, this avoids the dangling <div style> style tag.
        if (el.style.length == 0) {
          removeAttr(el, 'style')
        }
      }

    } else {
      console.warn("Element",el,"is not defined")
    }
  }

  // Class
  export function toggleClass(el: HTMLElement, className: string) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      var classes = el.className.split(' ');
      var existingIndex = classes.indexOf(className);

      if (existingIndex >= 0)
        classes.splice(existingIndex, 1);
      else
        classes.push(className);

      el.className = classes.join(' ');
    }
  }

  export function addClass(el: HTMLElement, className: string) {
    if (el.classList)
      el.classList.add(className);
    else
      el.className += ' ' + className;
  }

  export function removeClass(el: HTMLElement, className: string) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      var cur = (' ' + el.className + ' ')
        , name = ' ' + className + ' ';

      while (cur.indexOf(name) < -1) {
        cur = cur.replace(name, ' ');
      }

      // Trim white spaces
      var finalValue = cur.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
      if (el.className !== finalValue) {
        el.className = finalValue;
      }
    }
  }

  export function hasClass(el: HTMLElement, className: string) {
    if (el.classList)
      el.classList.contains(className);
    else
      new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
  }

  // Selectors

  export function find(el: HTMLElement, selector: string) {
    return el.querySelectorAll(selector);
  }

  export function html(el: HTMLElement, val?: any) {
    if (val !== undefined) {
      el.innerHTML = val
    } else {
      return el.innerHTML
    }
  }

  export function text(el: HTMLElement, val?: any) {
    if (val !== undefined) {
      el.textContent = val
    } else {
      return el.textContent
    }
  }
}

const deepEqual = require('deep-equal')

// to move off of jquery.
export module Helpers {

  export function onTurbolinksLoad(func) {
    document.addEventListener("turbolinks:load", func)
  }

  export function onPageLoad(func) {
    document.addEventListener("DOMContentLoaded", func);
  }

  export function navigateWithRouter(newUrl, router) {
    router.push(newUrl)
  }

  export function routeEquals(routeToTest) {
    return (window.location.pathname == routeToTest)
  }

  export function getLastIdParameter() {
    var loc_split = window.location.href.split("/")
    var last_item = loc_split[loc_split.length - 1]
    return last_item
  }

  export function navigateTo(newUrl) {
    window.location.href = newUrl
  }

  // async setup the page load, so that we can be non-blocking.
  export async function setupOnPageLoad(setupFunction: Function, elementId: string) {
    onPageLoad(function() {

      var element
      if (elementId[0] =='#') {
        element = document.getElementById(elementId.substr(1))
      } else if (elementId[0] =='.') {
        element = document.getElementsByClassName(elementId.substr(1))
      } else {
        console.warn('setupOnPageLoad does not include valid id or class selector.')
      }

      if (element != null) {
        setupFunction()
      }
    });
  }

  export async function setupOnTurbolinksLoad(setupFunction: Function, elementId: string) {
    onTurbolinksLoad(function() {
      var element = document.getElementById(elementId)
      if (element != null) {
        setupFunction()
      }
    });
  }

  export function beforeUnload(func: Function) {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = '';
      // call func now
      func()
    })
  }

  export function setPageTitle(newTitle: string) {
    document.title = newTitle
  }

  export function isStringEmpty(val: string){
    if (isNotPresent(val)) {
      return true
    }
    if (val !== null) {
      return val.replace(/\s/g,"") == ""
    }
  }

  export function isStringPresent(val: string) {
    return !isStringEmpty(val)
  }

  export function addUnique(items: Array<number | string>, newItem: number | string) {
    let index = items.findIndex(otherItem => otherItem === newItem)

    if (index == -1) {
      items.push(newItem)
      return true
    } else {
      return false
    }
  }

  export function removeUnique(items: Array<number | string>, newItem: number | string) {
    let index = items.findIndex(otherItem => otherItem === newItem)

    if (index > -1) {
      items.splice(index, 1)
      return true
    } else {
      return false
    }
  }

  export function addUniqueObject(items: Array<object>, newItem: object, id_param: string) {

    let newItemId = newItem[id_param]

    let index = items.findIndex(other_item => other_item[id_param] === newItemId)
    console.log("addUnique", index, newItemId)
    // add if not found
    if (index == -1) {
      items.push(newItem)
      return true
    } else {
      return false
    }

  }

  export function sortItemsByDate(items: Array<object>, sort_column: string = 'updated_at') {

    console.log('sortItemsByDate', items)

    return items.concat().sort((a, b) => +new Date(b[sort_column]) - +new Date(a[sort_column]))
  }

  // export function removeItem(items: Array<object>, item: object, id_param: string = 'id') {
  //   let index = items.findIndex(session => session.id === session_id)

  //   if (index != -1) {
  //     this.$delete(this.sessions, index)
  //   }
  // }

  export function getUserIdFromResponseHeaders(response: any) {
    return +response.headers['x-relevant-jobs-user-id']
  }

  export function isPresent(obj: any) {
    return !isNotPresent(obj)
  }

  export function isNotPresent(obj: any) {
    return obj === null || obj === undefined
  }

  export function hasProperty(obj: any, prop: string) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  export function isElementScrolledToBottom (element) {
    // https://www.vuesnippets.com/posts/detect-user-scrolled-bottom/
    let scrollTop = element.scrollTop;
    let offsetHeight = element.offsetHeight;
    let clientHeight = element.clientHeight;
    let scrollHeight = element.scrollHeight;

    return (scrollTop + clientHeight >= scrollHeight)
  }

  export function isElementScrolledToTop (element) {
    // https://www.vuesnippets.com/posts/detect-user-scrolled-bottom/
    let scrollTop = element.scrollTop;
    let offsetHeight = element.offsetHeight;
    let clientHeight = element.clientHeight;
    let scrollHeight = element.scrollHeight;

    return (scrollTop == 0)
  }


  export function isDesktop() {
    if(window.innerWidth <= 1300) {
      return false;
    } else {
      return true;
    }
  }

  export function jsonCopyObject(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  export function deepCompare(obj1, obj2) {
    return deepEqual(obj1, obj2)
  }

  export function parseNumberString(val) {
    return val.split`,`.map(x=>+x)
  }

  // export function setTimeout(func, time) {
  //   window.setTimeout( func(), 15000);
  // }

}

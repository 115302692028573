<template>

  <div class="skills-selection">
    <div class="selected-skills-section mb-1" v-if="value.length != 0">
      <div class="selected-skills" style="display: inline">
        <skill-button v-for="skill in selectedSkills" v-bind="skill" :key="skill.id" v-on:selected="unselectSkill" action-type="X" />
      </div>
    </div>
    <div class="skills-section mb-3">
      <b-form-input v-model="skill_search" ref="skill_search" placeholder="Add skill, eg: Ruby" v-if="showSearch" class="form-control-outline" />
      <b-button variant="primary" @click="showSearchInput" v-if="!showSearch" class="heavy-button">
        <i class="zmdi zmdi-collection-plus"></i>
        Add Skill
      </b-button>
      <div class="filtered-skills" v-if="isSearchPresent">
        <skill-button v-for="skill in filteredSkills" v-bind="skill" :key="skill.id" v-on:selected="selectSkill" action-type="+" />
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">

  import Vue from "vue";
  import Routes from 'routes';

  import FuzzySearch from 'fuzzy-search';
  import { ModalPlugin } from 'bootstrap-vue';
  Vue.use(ModalPlugin)

  import { Requests } from 'Packs/requests';
  import { Helpers } from 'Packs/helpers';

  import SkillButton from 'Components/SkillButton.vue'

  export default Vue.extend({
    data: function(){
      return {
        selected_skill_ids: [],
        filteredSkillKeys: [],
        skills: [],

        showSearch: false,

        skill_search: "",
      }
    },
    components: {
      'skill-button': SkillButton,
    },
    computed: {
      selectedSkills () {
        return this.skills.length != 0 && this.selected_skill_ids.map(pretty_param =>
          this.skills.find(skill => skill.pretty_param == pretty_param))
      },
      sortedSkills() {
        return this.skills.sort( (a,b) => (a.count > b.count) ? -1 : 1) // desc
      },
      filteredSkills() {
        return this.sortedSkills.filter( (skill) => {
          return !this.selected_skill_ids.includes(skill.pretty_param) &&
            this.filteredSkillKeys.includes(skill.pretty_param)
        }).slice(0,5)
      },
      isSearchPresent() {
        return Helpers.isStringPresent(this.skill_search)
      }
    },
    methods: {
      getSkills() {
        let vm = this
        Requests.get(Routes.api_skills_path()).then( (response) => {
          this.skills = response.data.skills

          vm.$emit("loaded-skills")

          // this.$nextTick( () => {
          //   this.selectedSkills()
          // });
        })
      },
      focusSearchInput() {
        this.$nextTick( () => {
          this.$refs.skill_search.$el.focus()
        });
      },
      showSearchInput (event) {
        this.showSearch = true
        this.focusSearchInput()
      },
      clickedAddSkill() {
        alert('clickedAddSkill')
      },
      cancelSelection() {
        this.selected_user = null
        this.selected = false
        this.search_text = ''
      },

      complete() {
        this.$emit("complete")
      },

      show() {
        this.$refs.skill_modal_internal.show()
      },

      unselectSkill(skill) {
        Helpers.removeUnique(this.selected_skill_ids, skill.pretty_param)
      },
      selectSkill(skill) {
        Helpers.addUnique(this.selected_skill_ids, skill.pretty_param)
        this.skill_search = ""
        this.focusSearchInput()
      },

      searchSkills (query) {
        const searcher = new FuzzySearch(this.skills, ['name','tags'], {
          caseSensitive: false,
        });
        const result = searcher.search(query);
        this.filteredSkillKeys = result.map(skill => skill.pretty_param)
      },
      skillsForCategory(category_id) {
        return this.skills.filter( (skill) => {
          return skill.category_id == category_id
        })
      },
    },
    created() {
      this.getSkills()
    },
    watch: {
      skill_search (newVal) {
        this.searchSkills(newVal)
      },
      selected_skill_ids(newVal) {
        this.$emit("input", newVal)
      },
      shouldShow(newVal) {
        alert("shouldShow")
      },
      value(newVal, oldVal) {
        console.log(`value ${newVal} -> ${oldVal} ${Helpers.deepCompare(newVal,oldVal)}`)

        if (!Helpers.deepCompare(newVal,oldVal)) {
          this.selected_skill_ids = this.value
        }
      }
    },
    mounted() {
    },
    props: {
      value: {
        type: Array,
        default () {
          return []
        }
      },
      shouldShow: {
        type: Boolean
      }

    },
  })

</script>


<style lang="scss">
</style>
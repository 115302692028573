import axios from 'axios'

// CORS will handle this for us.
// axios.defaults.withCredentials = true

// If on the same domain, the CORS info isn't sent.
// On other domains the Origin header is sent properly.

// import NProgress from 'nprogress'

axios.defaults.headers.common = {
  "Content-Type": "application/json",
  "Accept": "application/json"
};

// NProgress.configure({ parent: '.fullWidth__content' });

import { ProgressBar } from 'Packs/progressbar'

// // before a request is made start the nprogress
axios.interceptors.request.use(config => {
  ProgressBar.start()
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  // NProgress.done()
  ProgressBar.finish()
  return response
})


export module Requests {

  // function transport() {
  //   return axios.create({
  //     validateStatus: function (status)
  //     {
  //         return status < 500;
  //     }
  //   })
  // }

  export function get(url, config?) {
    return axios.get( url, config )
  }

  export function post(url,data?,config?) {
    return axios.post(url,data,config)
  }

  export function put(url,data?,config?) {
    return axios.put(url,data,config)
  }

}



import Vue from 'vue'
import { Helpers } from 'Packs/helpers'
import { Requests } from 'Packs/requests'
import { CurrentSearchVal } from 'Packs/current_search_val'

import Routes from 'routes';

// https://alligator.io/vuejs/global-event-bus/
import { EventBus } from 'Src/EventBus'

import NewJob from 'Components/NewJob.vue'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import SkillSelection from 'Components/SkillSelection.vue'

import FuzzySearch from 'fuzzy-search';

function setupNewJob() {

  const app = new Vue({
    name: "jobsNew",
    el: '#jobs-new',
    components: {
      'skills-selection': SkillSelection,
      'new-job': NewJob,
    },
    mounted() {
    },
    data () {
      return {
        search_results: [],
        selected_page: 1,
        pagination: {

        },

      }
    }
  });

}

Helpers.setupOnPageLoad(
  setupNewJob,
  "#jobs-new")

/*
File generated by js-routes 1.4.9
Based on Rails 5.2.4.2 routes of Relevantjobs::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_preview_job => /jobs/:id/accept_preview(.:format)
  // function(id, options)
  accept_preview_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_jobs => /api/jobs(.:format)
  // function(options)
  api_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_msearch => /api/msearch(.:format)
  // function(options)
  api_msearch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"msearch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_search => /api/search(.:format)
  // function(options)
  api_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_skills => /api/skills(.:format)
  // function(options)
  api_skills_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"skills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// apply_job => /jobs/:id/apply(.:format)
  // function(id, options)
  apply_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// card_job => /jobs/:id/card(.:format)
  // function(id, options)
  card_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// card_linkedin_job => /jobs/:id/card/linkedin(.:format)
  // function(id, options)
  card_linkedin_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"card",false],[2,[7,"/",false],[2,[6,"linkedin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// card_twitter_job => /jobs/:id/card/twitter(.:format)
  // function(id, options)
  card_twitter_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"card",false],[2,[7,"/",false],[2,[6,"twitter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// category => /categories/:pretty_param(.:format)
  // function(pretty_param, options)
  category_path: Utils.route([["pretty_param",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"pretty_param",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checkout => /checkouts/:id(.:format)
  // function(id, options)
  checkout_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checkouts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checkouts => /checkouts(.:format)
  // function(options)
  checkouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkouts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// debug_single => /debug/single(.:format)
  // function(options)
  debug_single_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"debug",false],[2,[7,"/",false],[2,[6,"single",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /logout(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// job => /jobs/:id(.:format)
  // function(id, options)
  job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// jobs => /jobs(.:format)
  // function(options)
  jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// login => /login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// mailkick => /mailkick
  // function(options)
  mailkick_path: Utils.route([], {}, [2,[7,"/",false],[6,"mailkick",false]]),
// mailkick.unsubscribe_subscription => /mailkick/subscriptions/:id/unsubscribe(.:format)
  // function(id, options)
  mailkick_unsubscribe_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"mailkick",false]],[7,"/",false]],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unsubscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mailkick.subscribe_subscription => /mailkick/subscriptions/:id/subscribe(.:format)
  // function(id, options)
  mailkick_subscribe_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"mailkick",false]],[7,"/",false]],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mailkick.subscription => /mailkick/subscriptions/:id(.:format)
  // function(id, options)
  mailkick_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"mailkick",false]],[7,"/",false]],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_checkout => /checkouts/new(.:format)
  // function(options)
  new_checkout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkouts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_job => /jobs/new(.:format)
  // function(options)
  new_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ok_computer => /_health
  // function(options)
  ok_computer_path: Utils.route([], {}, [2,[7,"/",false],[6,"_health",false]]),
// ok_computer.root => /_health/
  // function(options)
  ok_computer_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"_health",false]],[7,"/",false]]),
// ok_computer.okcomputer_checks => /_health/all(.:format)
  // function(options)
  ok_computer_okcomputer_checks_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_health",false]],[7,"/",false]],[2,[6,"all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ok_computer.okcomputer_check => /_health/:check(.:format)
  // function(check, options)
  ok_computer_okcomputer_check_path: Utils.route([["check",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_health",false]],[7,"/",false]],[2,[3,"check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// page => /pages/*id
  // function(id, options)
  page_path: Utils.route([["id",true]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[5,[3,"id",false],false]]]]),
// pg_hero => /pghero
  // function(options)
  pg_hero_path: Utils.route([], {}, [2,[7,"/",false],[6,"pghero",false]]),
// pg_hero.space => /pghero(/:database)/space(.:format)
  // function(options)
  pg_hero_space_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.relation_space => /pghero(/:database)/space/:relation(.:format)
  // function(relation, options)
  pg_hero_relation_space_path: Utils.route([["database",false],["relation",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[2,[7,"/",false],[2,[3,"relation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.index_bloat => /pghero(/:database)/index_bloat(.:format)
  // function(options)
  pg_hero_index_bloat_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"index_bloat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.live_queries => /pghero(/:database)/live_queries(.:format)
  // function(options)
  pg_hero_live_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"live_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.queries => /pghero(/:database)/queries(.:format)
  // function(options)
  pg_hero_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.show_query => /pghero(/:database)/queries/:query_hash(.:format)
  // function(query_hash, options)
  pg_hero_show_query_path: Utils.route([["database",false],["query_hash",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"query_hash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.system => /pghero(/:database)/system(.:format)
  // function(options)
  pg_hero_system_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.cpu_usage => /pghero(/:database)/cpu_usage(.:format)
  // function(options)
  pg_hero_cpu_usage_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"cpu_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connection_stats => /pghero(/:database)/connection_stats(.:format)
  // function(options)
  pg_hero_connection_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connection_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.replication_lag_stats => /pghero(/:database)/replication_lag_stats(.:format)
  // function(options)
  pg_hero_replication_lag_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"replication_lag_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.load_stats => /pghero(/:database)/load_stats(.:format)
  // function(options)
  pg_hero_load_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"load_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.free_space_stats => /pghero(/:database)/free_space_stats(.:format)
  // function(options)
  pg_hero_free_space_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"free_space_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.explain => /pghero(/:database)/explain(.:format)
  // function(options)
  pg_hero_explain_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"explain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.tune => /pghero(/:database)/tune(.:format)
  // function(options)
  pg_hero_tune_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"tune",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connections => /pghero(/:database)/connections(.:format)
  // function(options)
  pg_hero_connections_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.maintenance => /pghero(/:database)/maintenance(.:format)
  // function(options)
  pg_hero_maintenance_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill => /pghero(/:database)/kill(.:format)
  // function(options)
  pg_hero_kill_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_long_running_queries => /pghero(/:database)/kill_long_running_queries(.:format)
  // function(options)
  pg_hero_kill_long_running_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_long_running_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_all => /pghero(/:database)/kill_all(.:format)
  // function(options)
  pg_hero_kill_all_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.enable_query_stats => /pghero(/:database)/enable_query_stats(.:format)
  // function(options)
  pg_hero_enable_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"enable_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.reset_query_stats => /pghero(/:database)/reset_query_stats(.:format)
  // function(options)
  pg_hero_reset_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"reset_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.system_stats => /pghero(/:database)/system_stats(.:format)
  // function(options)
  pg_hero_system_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.query_stats => /pghero(/:database)/query_stats(.:format)
  // function(options)
  pg_hero_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.root => /pghero/(:database)(.:format)
  // function(options)
  pg_hero_root_path: Utils.route([["database",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[2,[1,[3,"database",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// preview_job => /jobs/:id/preview(.:format)
  // function(id, options)
  preview_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remote_jobs => /jobs/remote(.:format)
  // function(options)
  remote_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"remote",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// save_job => /jobs/:id/save(.:format)
  // function(id, options)
  save_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// search => /search(.:format)
  // function(options)
  search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// skill => /skills/:id(.:format)
  // function(id, options)
  skill_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"skills",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// skills => /skills(.:format)
  // function(options)
  skills_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"skills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// terms => /terms(.:format)
  // function(options)
  terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// thanks_job => /jobs/:id/thanks(.:format)
  // function(id, options)
  thanks_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"thanks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);


import Vue from 'vue'
import { Helpers } from 'Packs/helpers'
import { jqlite } from 'Packs/jqlite'
import { Requests } from 'Packs/requests'
import { CurrentSearchVal } from 'Packs/current_search_val'

import Routes from 'routes';

// https://alligator.io/vuejs/global-event-bus/
// import { EventBus } from 'Src/EventBus'

import JobRow from 'Components/JobRow.vue'
import JobsInstant from 'Components/jobs-instant.vue'
import SkillButton from 'Components/SkillButton.vue'
import SkillSelection from 'Components/SkillSelection.vue'
import SearchBar from "Components/SearchBar.vue"

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import FuzzySearch from 'fuzzy-search';

const queryString = require('query-string');

function setupInstantSearch() {
  const app = new Vue({
    name: "instantSearchResults",
    el: '#omnibar-wrapper',
    components: {
      'job-row': JobRow,
      'skill-button': SkillButton,
      'skill-selection': SkillSelection,
      'searchbar': SearchBar
    },
    data () {
      return {
        current_href: "",
        search_results: [],
        selected_page: 1,

        pagination: {
          current: 1,
          previous: null,
          next: null,
          per_page: 15,
          pages: null,
          count: 0
        },

        queryParams: {
          text: null,
          skills: []
        },

        hasLoaded: {
          skills: false,
          results: false
        },

        fullyLoaded: false,

        selected_skill_ids: [],

        filteredSkills: [],
        showingSearchResults: false,

        lastQueryJson: "",
      }
    },
    watch: {
      hasLoaded: {
        deep: true,
        handler (newval) {
          if (this.fullyLoaded) {
            return
          }

          if (newval.skills && newval.results) {
            jqlite.hide(jqlite.query("search-results-placeholder"))
            this.fullyLoaded = true
          }
        }
      }
    },
    computed: {
      currentPageIsSearch() {
        return window.location.pathname === "/search"
      },
      currentPageIsRoot() {
        return window.location.pathname === "/"
      }
    },
    methods: {
      checkForPath() {
        let pathName = window.location.pathname

        let isQueryEmpty = Helpers.isStringEmpty(this.queryParams.text)
        let isSkillsEmpty = (this.queryParams.skills.length == 0)

        if (pathName === "/search" && ( !isQueryEmpty || !isSkillsEmpty )) {
          this.originalPath = "/"
          this.showSearchResults()
        } else {
          this.originalPath = pathName
          // this.hideSearchResults()
        }
      },
      showSearchResults() {
        this.showingSearchResults = true
      },
      // hideSearchResults() {
      //   console.log("hideSearchResults")
      // },

      omnibar_enter() {
        if (this.showingSearchResults) {
          this.setHistory()
        } else {
          let ids = this.queryParams.skills
          let text = this.queryParams.text

          let newQuery = "?"+queryString.stringify({skills: ids, q: text}, {arrayFormat: 'comma'})

          let searchUrl = Routes.search_path() + newQuery
          Helpers.navigateTo(searchUrl)
        }

      },

      popState(event) {
        this.loadQueryParams()
        //         this.current_href = window.location.href
      },
      scrollToTopOfJobs() {
        console.log("scrollToTopOfJobs", this.$el)
        this.$nextTick(() => {
          window.scrollTo(0,0)
        });

      },
      // pagination fire event
      changedPage(newPage) {
        console.log("changedPage", newPage)

        this.selected_page = newPage
        this.setHistory()
      },

      buildQuery() {
        let query = {}

        if (Helpers.isStringPresent(this.queryParams.text)) {
          query['q'] = this.queryParams.text
        }


        if (this.queryParams.skills.length != 0) {
          query['skills'] = this.queryParams.skills.join()
          query['skill_ids'] = this.queryParams.skills
        }

        if (this.selected_page != 1) {
          query['page'] = this.selected_page
        }

        return query
      },
      search () {
        let buildQuery = this.buildQuery()
        let buildQueryJson = JSON.stringify(buildQuery)

        if (this.lastQueryJson == buildQueryJson) {
          console.log(`duplicate search() -> ${this.lastQueryJson} == ${buildQueryJson}`)
          return
        }

        this.lastQueryJson = buildQueryJson

        console.log("search()")

        Requests.get(Routes.api_msearch_path(buildQuery)).then( (response) => {
          this.hasLoaded.results = true
          this.scrollToTopOfJobs()
          this.search_results = response.data.data
          this.pagination     = response.data.pagination
          console.log(response.data)
        })
      },

      setHistory() {
        let newQuery = this.buildQuery()
        let newQueryJson = JSON.stringify(newQuery)

        if (this.lastQueryJson == newQueryJson) {
          console.log(`duplicate search() -> ${this.lastQueryJson} == ${newQuery}`)
          return
        }

        let newHistoryPath = Routes.search_path() + "?"+queryString.stringify({skills: newQuery.skill_ids, q: newQuery.q, page: newQuery.page}, {arrayFormat: 'comma'})

        history.pushState({},'', newHistoryPath)
        this.search()

        this.current_href = window.location.href
      },

      updatedSkillsFromSelection(newSkills) {

        this.queryParams.skills = newSkills
        this.$nextTick(() => {
          this.setHistory()
        });
      },
      loadQueryParams() {
        const parsed = queryString.parse(location.search)

        console.log(`loadQueryParams -> page: ${parsed.page}`)

        if (parsed && parsed.page) {
          this.selected_page = parsed.page;
        } else {
          this.selected_page = 1
        }

        if (parsed && parsed.q) {
          this.queryParams.text = parsed.q;
        } else {
          this.queryParams.text = ""
        }

        if (parsed && parsed.skills) {
          this.queryParams.skills = parsed.skills.split(",")
        } else {
          this.queryParams.skills = []
        }

        if (this.queryParams.skills.length > 0 || !Helpers.isStringEmpty(this.queryParams.text) || this.currentPageIsSearch) {
          this.search()
        }

        this.checkForPath()
      },

      reloadPage() {
        const parsed = queryString.parse(location.search)

        if (parsed && parsed.page) {
          this.selected_page = parsed.page;
        }
      }

    },
    mounted () {
      jqlite.hide( jqlite.query("tempSearch") )

      console.log("instantSearchResults#mounted")
      this.loadQueryParams()

      this.current_href = window.location.href

      // don't bind to window.popstate, just the event
      window.addEventListener('popstate',  this.popState)
    }

  });

}

Helpers.setupOnPageLoad(
  setupInstantSearch,
  "#omnibar-wrapper")
